<template>
  <li>
    <label :class="['choice-box', { 'is-active': selected }]">
      <button
        class="w-full flex justify-start items-center"
        @click="handleClick"
      >
        <input class="radio-button" type="radio" :checked="selected">
        <span class="ml-3">{{ label }}</span>
      </button>
    </label>
    <div :class="['py-4 space-y-5', { 'hidden': !selected }]">
      <slot/>
    </div>
  </li>
</template>

<script>
export default {
  name: 'px-accordion-item',
  props: {
    index: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    selected: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit('clickPxAccordionItemLabel', this.index);
    },
  },
};
</script>
