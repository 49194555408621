<template>
  <div>
    <label class="form-label">{{ label }}</label>
    <div class="input-group has-addon flex">
      <div class="input-addon bg-gray-200 rounded-l-md">
        <div class="select dropdown">
          <button class="button button-sm dropdown-toggle" @click="handleToggle">
            <span>{{ items[this.selectedItemIndex] }}</span>
          </button>
          <div :class="['dropdown-menu', { 'hidden': !open }]">
            <ol class="dropdown-menu-list">
              <li
                v-for="(item, index) in items"
                :key="index"
                :class="['dropdown-menu-item', { 'is-active': isActive(index) }]"
              >
                <button class="dropdown-menu-button" @click="handleSelect(index)">
                  <span>{{ item }}</span>
                </button>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="input-control">
        <input
          type="type"
          class="input-field"
          :placeholder="placeholder"
          :value="inputValue"
          @input="handleInput"
        />
      </div>
    </div>
     <div class="text-red-700 text-sm mt-2" v-show="showRequiredError && type !== 'Email'">
        Preencha este campo
      </div>
      <div class="text-red-700 text-sm mt-2" v-show="showRequiredError && type === 'Email'">
        Email Inválido
      </div>
  </div>
</template>

<script>
import utilValidator from '../../util/util.validator';

export default {
  name: 'px-input-select',
  props: {
    items: {
      type: Array,
      required: true,
    },
    label: String,
    name: String,
    placeholder: String,
    value: Object,
  },
  data: () => ({
    open: false,
    selectedItemIndex: 0,
    inputValue: '',
    showRequiredError: false,
    inputSelect: '',
    type: '',
  }),
  watch: {
    value(value) {
      this.setInputValue(value.inputValue);
      this.setSelectedItem(value.selectValue);
    },
  },
  methods: {
    setInputValue(inputValue) {
      if (inputValue) {
        this.inputValue = inputValue;
      }
    },
    setSelectedItem(selectValue) {
      if (selectValue) {
        this.selectedItemIndex = this.items.findIndex(
          (item) => item === selectValue,
        );
      }
    },
    handleToggle() {
      this.open = !this.open;
    },
    handleSelect(index) {
      this.open = false;
      this.selectedItemIndex = index;
      this.$emit('input', {
        selectValue: this.items[this.selectedItemIndex],
        inputValue: this.inputValue,
      });
      this.type = this.items[this.selectedItemIndex];
    },
    handleInput(event) {
      this.inputValue = event.target.value.toString().trim();
      if (this.type === 'Email') {
        if (utilValidator.isValidEmail(this.inputValue)) {
          this.showRequiredError = false;
        } else {
          this.showRequiredError = true;
        }
      }
      this.$emit('input', {
        selectValue: this.items[this.selectedItemIndex],
        inputValue: this.inputValue,
      });
    },
    isActive(index) {
      return this.selectedItemIndex === index;
    },
  },
  mounted() {
    this.$parent.$on('invalidInput', (invalidInputList) => {
      this.showRequiredError = invalidInputList
        .find((inputName) => inputName === this.name);
    });
  },
};
</script>
