<template>
  <div class="mb-3">
    <label class="form-label" v-if="this.$slots.default">
      <slot/>
    </label>

    <div class="flex gap-2 justify-center">
      <input
        v-for="(n, index) in length"
        :key="index"
        type="text"
        class="input-field text-center w-12 lg:w-btn px-2 no-caret"
        ref="inputList"
        :autofocus="index === 0"
        @input="handleInput($event, index)"
        @keydown="handleKeyDown($event, index)"
      />
    </div>

    <div
      v-if="status"
      :class="['flex items-center justify-center mt-4', statusClass]"
    >
      <MinusCircleIcon size="20" v-if="status === 'failure'"/>
      <px-animation-loading v-if="status === 'loading'"/>
      <CheckCircleIcon size="20" v-if="status === 'success'"/>
      <span class="ml-1">{{ statusText }}</span>
    </div>
  </div>
</template>

<script>
import { CheckCircleIcon, MinusCircleIcon } from '@vue-hero-icons/solid';
import PxAnimationLoading from './PxAnimationLoading.vue';
import validatorUtil from '../../util/util.validator';

const PxInputCodeStatusEnum = {
  failure: {
    class: 'text-red-700',
    text: 'Código inválido',
  },
  loading: {
    class: 'text-gray-500',
    text: 'Verificando...',
  },
  success: {
    class: 'text-emerald-700',
    text: 'Código válido',
  },
};

export default {
  name: 'px-input-code',
  components: {
    CheckCircleIcon,
    MinusCircleIcon,
    PxAnimationLoading,
  },
  props: {
    length: {
      type: Number,
      default: 4,
    },
    status: {
      type: String,
      validator: validatorUtil.isOfType(PxInputCodeStatusEnum),
    },
    value: String,
  },
  data: () => ({
    valueArray: [],
  }),
  computed: {
    statusClass() {
      return PxInputCodeStatusEnum[this.status].class;
    },
    statusText() {
      return PxInputCodeStatusEnum[this.status].text;
    },
  },
  methods: {
    stringToArray: (value) => value.split(''),
    arrayToString: (value) => value.join(''),
    isComplete() {
      const index = this.valueArray.find((value) => value === '');
      return index === undefined;
    },
    previousIndex(currentIndex) {
      return currentIndex <= 0 ? 0 : currentIndex - 1;
    },
    nextIndex(currentIndex) {
      return currentIndex >= this.length - 1
        ? this.length - 1
        : currentIndex + 1;
    },
    updateInputValue(value) {
      this.valueArray = value;
      this.$refs.inputList.forEach((input, index) => {
        // eslint-disable-next-line no-param-reassign
        input.value = this.valueArray[index];
      });
    },
    updateInputFocus(index) {
      this.$refs.inputList[index].focus();
    },
    handleInput(event, inputIndex) {
      let currentInputIndex = inputIndex;
      let inputDataIndex = 0;
      let inputData = event.target.value.toString().trim();
      if (event.inputType === 'insertText') {
        inputData = event.data.toString().trim();
      }

      const inputDataArray = this.stringToArray(inputData);
      const currentValueArray = this.valueArray.map((value, index) => {
        if (index < inputIndex || inputDataIndex >= inputDataArray.length) {
          return value;
        }
        currentInputIndex = this.nextIndex(currentInputIndex);
        inputDataIndex += 1;
        return inputDataArray[inputDataIndex - 1];
      });

      this.updateInputValue(currentValueArray);
      this.updateInputFocus(currentInputIndex);

      this.$emit('input', this.arrayToString(this.valueArray));
      if (this.isComplete()) {
        this.$emit('complete', this.arrayToString(this.valueArray));
      }
    },
    handleKeyDown(event, inputIndex) {
      let currentInputIndex = inputIndex;
      const currentValueArray = [...this.valueArray];
      switch (event.key) {
        case 'ArrowLeft':
          this.updateInputFocus(this.previousIndex(inputIndex));
          break;
        case 'ArrowRight':
          this.updateInputFocus(this.nextIndex(inputIndex));
          break;
        case 'Backspace':
          event.preventDefault();
          if (currentValueArray[currentInputIndex] === '') {
            currentInputIndex = this.previousIndex(currentInputIndex);
            currentValueArray[currentInputIndex] = '';
          } else {
            currentValueArray[currentInputIndex] = '';
            currentInputIndex = this.previousIndex(currentInputIndex);
          }
          this.updateInputValue(currentValueArray);
          this.updateInputFocus(currentInputIndex);
          currentValueArray[inputIndex] = '';
          this.$emit('input', this.arrayToString(this.valueArray));
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    for (let i = 0; i < this.length; i += 1) {
      this.valueArray.push('');
    }
  },
};
</script>

<style scoped>
input.no-caret {
  caret-color: transparent;
}
</style>
