<template>
  <div>
    <label class="inline-flex items-start">
      <input type="checkbox" class="checkbox" value="checkbox" @input="handleInput">
      <span class="ml-2">
        <slot></slot>
      </span>
    </label>

    <div class="text-red-700 text-sm mt-2" v-show="showRequiredError">
      Preencha este campo
    </div>
  </div>
</template>

<script>
export default {
  name: 'px-checkbox',
  props: {
    value: Boolean,
    name: String,
  },
  data: () => ({
    showRequiredError: false,
  }),
  methods: {
    handleInput() {
      this.$emit('input', !this.value);
    },
  },
  mounted() {
    this.$parent.$on('invalidInput', (invalidInputList) => {
      this.showRequiredError = invalidInputList
        .find((inputName) => inputName === this.name);
    });
  },
};
</script>
