<template>
  <div>
    <ol :class="`stepper${ mobile ? '-mobile' : '' }`">
      <li
        v-for="(step, index) in steps"
        :key="index"
        :class="[
          { 'is-done': index < currentStep },
          { 'is-active': index == currentStep },
        ]"
      >
        <div :class="{ 'sr-only': mobile }">
          {{ step }}
        </div>
      </li>
    </ol>
    <h1
      v-if="mobile"
      class="font-bold text-white text-xl text-center mt-4"
    >
      {{ steps[currentStep] }}
    </h1>
  </div>
</template>

<script>
import UtilMixin from '@/mixins/util';

export default {
  name: 'px-stepper',
  props: {
    steps: Array,
    currentStep: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    mobile: false,
  }),
  mixins: [UtilMixin],
  mounted() {
    this.mobile = this.isMobile();
  },
};
</script>
