<template>
  <div>
    <label class="form-label" v-if="this.$slots.default">
      <slot/>
    </label>
    <div :class="[
      'input-group',
      { 'has-icon': this.$slots.left },
      { 'has-icon-group': loading },
    ]">
      <div class="input-control">
        <div class="input-icon" v-if="this.$slots.left">
          <slot name="left"/>
        </div>

        <input
          class="input-field"
          :type="type"
          :placeholder="placeholder"
          :value="formattedValue"
          :disabled="disabled"
          @input="handleInput"
        />

        <div class="input-icon-group p-2 lg:p-1" v-show="loading">
          <!-- eslint-disable max-len -->
          <svg class="animate-spin h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle
             class="opacity-25"
             cx="12"
             cy="12"
             r="10"
             stroke="currentColor"
             stroke-width="4">
            </circle>
            <path
              class="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
          </svg>
        </div>
      </div>

      <div class="text-red-700 text-sm mt-2" v-if="invalidFeedback">
        {{ invalidFeedback }}
      </div>
      <div class="text-red-700 text-sm mt-2" v-show="showRequiredError && type !== 'email'">
        Preencha este campo
      </div>
      <div class="text-red-700 text-sm mt-2" v-show="showRequiredError && type ==='email'">
        Email Inválido
      </div>
    </div>
  </div>
</template>

<script>
import inputMasksMixin from '@/mixins/inputMasks';

export default {
  name: 'px-input',
  model: {
    prop: 'value',
    event: 'pxInput',
  },
  mixins: [inputMasksMixin],
  props: {
    mask: String,
    loading: Boolean,
    maxlength: Number,
    minlength: Number,
    name: String,
    placeholder: String,
    type: {
      type: String,
      default: 'text',
    },
    value: [String, Number],
    disabled: {
      type: Boolean,
      default: false,
    },
    invalidFeedback: String,
  },
  data: () => ({
    showRequiredError: false,
  }),
  computed: {
    formattedValue() {
      let returnValue = this.value;
      if (this.maxlength) {
        returnValue = returnValue.slice(0, this.maxlength);
      }
      if (this.mask) {
        returnValue = this[this.mask](returnValue);
      }
      return returnValue;
    },
  },
  methods: {
    getNumbersOnly(value) {
      return value.replace(/\D/g, '');
    },
    handleInput(event) {
      let returnValue = event.target.value.toString().trim();
      if (this.mask === 'currencyBRL') {
        returnValue = event.target.value.replace(/^[^1-9]*|\.|,/g, '');
      } else if (['phone', 'cnpj', 'cep'].includes(this.mask)) {
        returnValue = this.getNumbersOnly(event.target.value);
      }
      this.$emit('pxInput', returnValue);
    },
  },
  mounted() {
    this.$parent.$on('invalidInput', (invalidInputList) => {
      this.showRequiredError = invalidInputList
        .find((inputName) => inputName === this.name);
    });
  },
};
</script>
