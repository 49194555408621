<template>
  <div>
    <ul class="space-y-4">
      <slot/>
    </ul>

    <div v-show="showRequiredError" class="text-red-700 text-sm mt-2">
      Preencha uma das opções
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import PxAccordionItem from './PxAccordionItem.vue';

export default {
  name: 'px-accordion',
  props: {
    name: String,
  },
  data: () => ({
    selectedItemIndex: -1,
    showRequiredError: false,
  }),
  methods: {
    createAccordion() {
      this.$slots.default = this.$slots.default.map((child, index) => {
        const listItemDataObject = {
          props: {
            index,
            label: child.data.attrs.label,
            selected: this.selectedItemIndex === index,
          },
          on: { clickPxAccordionItemLabel: () => { this.handleToggle(index); } },
        };
        return this.$createElement(PxAccordionItem, listItemDataObject, [child]);
      });
    },
    handleToggle(itemIndex) {
      this.$children.forEach((child, index) => {
        if (index === itemIndex) {
          child.selected = !child.selected;
          this.selectedItemIndex = child.selected ? index : -1;
        } else {
          child.selected = false;
        }
      });
    },
  },
  beforeMount() {
    this.createAccordion();
  },
  mounted() {
    this.$parent.$on('invalidInput', (invalidInputList) => {
      this.showRequiredError = invalidInputList
        .find((inputName) => inputName === this.name);
    });
  },
};
</script>
