<template>
  <div>
    <div class="flex flex-col justify-between gap-4">
      <div
        v-for="item in selectorOptions"
        :key="item.id"
      >
        <label :class="['choice-box', { 'is-active': item.selected }]">
          <button
            class="w-full flex justify-start items-center"
            @click="handleSelect(item.id)"
          >
            <input class="radio-button" type="radio" :checked="item.selected">
            <span class="ml-3">{{ item.label }}</span>
          </button>
        </label>
        <div :class="[{ 'hidden': !item.selected }]">
          <slot/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'px-accordion-v2',
  props: {
    selectorOptions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    selectedItemId: -1,
  }),
  methods: {
    handleSelect(id) {
      if (this.selectedItemId === id) {
        this.selectorOptions.find((el) => el.id === this.selectedItemId).selected = false;
        this.selectedItemId = -1;
      } else if (this.selectedItemId !== id) {
        this.selectedItemId = id;
        this.selectorOptions.find((el) => el.id === this.selectedItemId).selected = true;
        this.selectorOptions.find((el) => el.id !== this.selectedItemId).selected = false;
      }
    },
  },
};
</script>
