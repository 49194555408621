<template>
  <div>
    <label class="form-label" :title="title">{{ label }}</label>
    <div class="select dropdown">
      <button
        :class="['button', 'select-toggle', {'icons-toggle': icon}]"
        :disabled="disabled"
        @click="handleToggle"
      >
        <div>
          <px-icon
            v-if="icon"
            :name="setIcon()"
            :alt="iconAlt"
            :class="['inline', 'mr-3', { 'hidden': !icon }]"
          />
          <span v-html="renderItem(selectedItem)" class="inline"/>
        </div>
        <px-icon
          :name="setChevron()"
          alt="Ícone de uma seta para baixo"
          class="inline w-5"
        />
      </button>
      <div :class="['dropdown-menu', dropdownWidthClass, { 'hidden': !open }]">
        <ol class="dropdown-menu-list overflow-y-auto">
          <li
            v-for="(item, index) in items"
            :key="index"
            :class="['dropdown-menu-item', { 'is-active': isActive(index) }]"
          >
            <button class="relavite dropdown-menu-button" @click="handleSelect(index)">
              <px-icon
                :name="'check-primary.svg'"
                :class="['absolute', 'left-3', 'mr-3', { 'hidden': selectedItemIndex !== index }]"
              />
              <px-icon
                v-if="subIcons"
                :name="setListIcon(item)"
                :class="['inline', 'mr-3', { 'hidden': !icon }]"
              />
              <span v-html="renderItem(item)"/>
            </button>
          </li>
        </ol>
      </div>
    </div>

    <div class="text-red-700 text-sm mt-2" v-show="showRequiredError">
      Selecione uma das opções
    </div>
  </div>
</template>

<script>
import PxIcon from '@/components/custom/PxIcon.vue';

export default {
  name: 'px-select',
  components: {
    PxIcon,
  },
  props: {
    name: String,
    items: {
      type: Array,
      required: true,
    },
    label: String,
    placeholder: String,
    shrinkDropdown: Boolean,
    title: String,
    value: [String, Object],
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: String,
    iconAlt: String,
    disabledIcon: String,
    subIcons: Array,
  },
  data: () => ({
    open: false,
    selectedItemIndex: -1,
    showRequiredError: false,
  }),
  computed: {
    dropdownWidthClass() {
      if (this.shrinkDropdown) return 'pb-0';
      return 'w-full';
    },
    selectedItem() {
      if (this.selectedItemIndex !== -1) return this.items[this.selectedItemIndex];
      return this.placeholder;
    },
  },
  watch: {
    value(newValue) {
      this.setSelectedItem(newValue);
    },
  },
  methods: {
    isObject(item) {
      return typeof item === 'object';
    },
    isActive(index) {
      if (!this.anySelected) return false;
      return this.selectedItemIndex === index;
    },
    renderItem(item) {
      if (!this.isObject(item)) return item;
      return item.label;
    },
    areEquals(item1, item2) {
      return JSON.stringify(item1) === JSON.stringify(item2);
    },
    handleToggle() {
      this.open = !this.open;
    },
    handleSelect(index) {
      this.open = false;
      this.selectedItemIndex = index;
      const inputReturn = this.isObject(this.items[this.selectedItemIndex])
        ? this.items[this.selectedItemIndex].value
        : this.items[this.selectedItemIndex];
      this.$emit('select', inputReturn);
    },
    setIcon() {
      if (this.disabled && this.disabledIcon) return this.disabledIcon;
      if (!this.icon) return null;
      return this.icon;
    },
    setListIcon(value) {
      return this.subIcons.find((item) => item.value === value).icon;
    },
    setSelectedItem(value) {
      if (value) {
        const itemIndex = this.items.findIndex((item) => {
          if (typeof item === 'object') return this.areEquals(item.value, value);
          return this.areEquals(item, value);
        });
        if (itemIndex !== -1) this.selectedItemIndex = itemIndex;
        else {
          this.selectedItemIndex = -1;
          this.setIcon();
        }
      }
    },
    setChevron() {
      if (this.disabled) return 'chevron-down-disabled.svg';
      return 'chevron-down.svg';
    },
  },
  created() {
    if (!this.placeholder) this.selectedItemIndex = 0;
    this.setSelectedItem(this.value);
  },
  mounted() {
    this.$parent.$on('invalidInput', (invalidInputList) => {
      this.showRequiredError = invalidInputList
        .find((inputName) => inputName === this.name);
    });
  },
};
</script>
