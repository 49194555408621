<template>
  <div class="w-full">
    <label :class="['choice-box', { 'is-active': selected }]">
      <button
        class="w-full flex justify-start items-center"
        @click="handleClick"
      >
        <input class="radio-button" type="radio" :checked="selected">
        <span class="ml-3">{{ label }}</span>
      </button>
    </label>
  </div>
</template>

<script>
export default {
  name: 'px-radio-selector-item',
  props: {
    id: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    selected: Boolean,
  },
  methods: {
    handleClick() {
      this.$emit('clickPxRadioSelectorItemLabel', this.id);
    },
  },
};
</script>
