<template>
  <div>
    <label class="form-label"><slot/></label>
    <div class="color-swatch input-group w-3/4 lg:w-48">
      <div class="input-control">
        <button ref="buttonColorPicker" class="input-group-color" @click="handleClick">
          <input
            type="color"
            style="height: 0; width: 0; visibility: hidden"
            ref="inputColor"
            :value="actualValue"
            @input="selectColor"
          />
        </button>
        <input
          type="text"
          class="input-field"
          :maxlength="MAX_LENGTH"
          :placeholder="value || defaultColor"
          @input="inputColor"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'px-input-color-picker',
  props: {
    value: String,
    defaultColor: {
      type: String,
      default: '#FFFFFF',
    },
  },
  data: () => ({
    MAX_LENGTH: 7,
    lastValidValue: '',
  }),
  computed: {
    validHexColor() {
      return this.value.match(/#[A-Fa-f0-9]{6}/);
    },
    actualValue() {
      if (this.validHexColor) return this.value;
      return this.lastValidValue;
    },
  },
  methods: {
    handleClick() {
      this.$refs.inputColor.click();
    },
    selectColor(event) {
      this.lastValidValue = event.target.value;
      this.$refs.buttonColorPicker.style.backgroundColor = this.lastValidValue;
      this.$emit('input', this.lastValidValue);
    },
    inputColor(event) {
      const inputValue = event.target.value;
      if (this.validHexColor) {
        this.lastValidValue = inputValue;
        this.$refs.buttonColorPicker.style.backgroundColor = this.lastValidValue;
      }
      this.$emit('input', inputValue);
    },
  },
  mounted() {
    this.lastValidValue = this.value || this.defaultColor;
    this.$refs.buttonColorPicker.style.backgroundColor = this.lastValidValue;
    this.$emit('input', this.lastValidValue);
  },
};
</script>
