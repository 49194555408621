<template>
  <div>
    <div class="flex justify-between items-center space-x-4 mb-5">
      <px-radio-selector-item
        v-for="item in selectorOptions"
        :key="item.id"
        :label="item.label"
        :id="item.id"
        :selected="item.selected"
        @clickPxRadioSelectorItemLabel="handleSelect"
      />
    </div>

    <slot name="content" />

  </div>
</template>

<script>
import PxRadioSelectorItem from './PxRadioSelectorItem.vue';

export default {
  name: 'px-radio-selector',
  components: {
    PxRadioSelectorItem,
  },
  props: {
    selectorOptions: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    showRequiredError: false,
    selectedItemId: -1,
  }),
  methods: {
    handleSelect(id) {
      if (this.selectedItemId === id) {
        this.selectorOptions.find((el) => el.id === this.selectedItemId).selected = false;
        this.selectedItemId = -1;
      } else if (this.selectedItemId !== id) {
        this.selectedItemId = id;
        this.selectorOptions.find((el) => el.id === this.selectedItemId).selected = true;
        this.selectorOptions.find((el) => el.id !== this.selectedItemId).selected = false;
      }
    },
  },
  mounted() {
    this.$parent.$on('invalidInput', (invalidInputList) => {
      this.showRequiredError = invalidInputList
        .find((inputName) => inputName === this.name);
    });
  },
};
</script>
